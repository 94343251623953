import React, {useState} from 'react';
import PopupVideo from './PopupVideo';
import PopupPages from './PopupPages';
import Tutorial from "../images/lab-tutorial.mp4";
import Old from "../images/lab-old.mp4";
import Video from "../images/lab-popup.mp4";
import GifSwitcher from "./GifSwitcher";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const LAB = () => {
    const pages = [
        <div>
            <div className="page-content">
                <PopupVideo src={Video} />
            </div>
            <div className="page-description">
                <p>A 3D puzzle game I developed solo, using Unity.</p>
            </div>
        </div>,
        <div>
            <div className="page-content">
                <PopupVideo src={Tutorial} />
            </div>
            <div className="page-description">
                <p>An introductory tutorial when playing the first level.</p>
            </div>
        </div>,
        <div>
            <div className="page-content">
                <PopupVideo src={Old} />
            </div>
            <div className="page-description">
                <p>LAB originally started as a 2D puzzle-card hybrid game.</p>
            </div>
        </div>,
    ];

    return (
        <div>
            <div className="popup-header">
                <div className="popup-title-container">
                    <h1><span><span className="title">LAB</span></span></h1>
                </div>
                <div className="popup-platform">
                    <svg viewBox="0 0 576 512">
                        <path d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z"/>
                    </svg>
                    <svg viewBox="0 0 576 512">
                        <path d="M420.6 301.9a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m-265.1 0a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m273.7-144.5 47.9-83a10 10 0 1 0 -17.3-10h0l-48.5 84.1a301.3 301.3 0 0 0 -246.6 0L116.2 64.5a10 10 0 1 0 -17.3 10h0l47.9 83C64.5 202.2 8.2 285.6 0 384H576c-8.2-98.5-64.5-181.8-146.9-226.6"/>
                    </svg>
                    <svg viewBox="0 0 576 512">
                        <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                    </svg>
                </div>
                {/*<div className="popup-platform"><img src={PhoneIcon}/></div>*/}
            </div>
            <div className="popup-pages">
                <PopupPages pages={pages}/>
            </div>
        </div>
    );
};

export default LAB;