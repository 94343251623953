import React, {useState} from 'react';
import PopupVideo from './PopupVideo';
import PopupPages from './PopupPages';
import Logo from "../images/nostalgitrippen-logo.png";
import VideoFile from "../images/nostalgitrippen-popup.mp4";
import Menu from "../images/nostalgitrippen-duel-menu.png";
import Lobby from "../images/nostalgitrippen-duel-lobby.png";
import GifSwitcher from "./GifSwitcher";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const BoT = () => {
    const pages = [
        <div>
            <div className="page-content">
                <img src={Logo}/>
                <div className="store-links">
                    <a href="https://apps.apple.com/se/app/nostalgitrippen/id6456891034">
                        <div className="store-link app-store">
                            <span>App Store</span>
                            <svg viewBox="0 0 384 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill="white"
                                      d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
            <div className="page-description">
                <p>I created most of the UI assets for Nostalgitrippen.</p>
            </div>
        </div>,
        <div>
            <div className="page-content">
                <PopupVideo src={VideoFile} />
            </div>
            <div className="page-description">
                <p>All assets were created in Figma.</p>
            </div>
        </div>,
        <div>
            <div className="page-content-row">
                <img src={Menu}/>
                <img src={Lobby}/>
            </div>
            <div className="page-description">
                <p>Mockups for a duel mode.</p>
            </div>
        </div>,
    ];

    return (
        <div>
            <div className="popup-header">
                <div className="popup-title-container">
                    <h1><span><span className="title">Nostalgitrippen</span></span></h1>
                </div>
                <div className="popup-platform">
                    <svg viewBox="0 0 576 512">
                        <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                    </svg>
                </div>
                {/*<div className="popup-platform"><img src={PhoneIcon}/></div>*/}
            </div>
            <div className="popup-pages">
                <PopupPages pages={pages}/>
            </div>
        </div>
    );
};

export default BoT;