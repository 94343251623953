import React, {useState} from 'react';
import PopupVideo from './PopupVideo';
import PopupPages from './PopupPages';
import VideoFile from "../images/intoorbit-popup.mp4";
import GifSwitcher from "./GifSwitcher";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const IntoOrbit = () => {
    const pages = [
        <div>
            <div className="page-content">
                <PopupVideo src={VideoFile} />
            </div>
            <div className="page-description">
                <p>I collaborated with a friend to create and publish a game within a week, with working ads.</p>
            </div>
        </div>,
    ];

    return (
        <div>
            <div className="popup-header">
                <div className="popup-title-container">
                    <h1><span><span className="title">Into Orbit</span></span></h1>
                </div>
                <div className="popup-platform">
                    <svg viewBox="0 0 576 512">
                        <path d="M420.6 301.9a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m-265.1 0a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m273.7-144.5 47.9-83a10 10 0 1 0 -17.3-10h0l-48.5 84.1a301.3 301.3 0 0 0 -246.6 0L116.2 64.5a10 10 0 1 0 -17.3 10h0l47.9 83C64.5 202.2 8.2 285.6 0 384H576c-8.2-98.5-64.5-181.8-146.9-226.6"/>
                    </svg>
                </div>
            </div>
            <div className="popup-pages">
                <PopupPages pages={pages}/>
            </div>
        </div>
    );
};

export default IntoOrbit;