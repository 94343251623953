import React, {useState} from 'react';
import PopupVideo from './PopupVideo';
import PopupPages from './PopupPages';
import VideoFile from "../images/i-roll-popup.mp4";
import BetweenTheCliffs from "../images/between-the-cliffs.png";
import DesertTwinPeaks from "../images/desert-twin-peaks.png";
import DragonsNest from "../images/dragons-nest.png";
import Menu from "../images/nostalgitrippen-duel-menu.png";
import Lobby from "../images/nostalgitrippen-duel-lobby.png";
import PhoneIcon from "../images/phone-icon.png"
import NewRecord from "../images/bot-new-record.gif"
import GifSwitcher from "./GifSwitcher";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const BoT = () => {
    const pages = [
        <div>
            <div className="page-content">
                <img src={DragonsNest}/>
            </div>
            <div className="page-description">
                <p>Dragons' Nest</p>
            </div>
        </div>,
        <div>
            <div className="page-content">
                <img src={BetweenTheCliffs}/>
            </div>
            <div className="page-description">
                <p>Between The Cliffs</p>
            </div>
        </div>,
        <div>
            <div className="page-content">
                <img src={DesertTwinPeaks}/>
            </div>
            <div className="page-description">
                <p>Desert Twin Peaks</p>
            </div>
        </div>,
    ];

    return (
        <div>
            <div className="popup-header">
                <div className="popup-title-container">
                    <h1><span><span className="title">Landscapes</span></span></h1>
                </div>
            </div>
            <div className="popup-pages">
                <PopupPages pages={pages}/>
            </div>
        </div>
    );
};

export default BoT;