import React, { useState } from 'react';
import PopupVideo from './PopupVideo';
import PopupPages from './PopupPages';
import VideoFile from "../images/i-roll-popup.mp4";
import Death from "../images/iroll-death.mp4";
import Old from "../images/iroll-old.mp4";
import logo from "../images/iroll-logo.png";
import PCIcon from "../images/pc-icon.png"
import TimerA from "../images/iroll-timer-A.gif"
import TimerB from "../images/iroll-timer-B.gif"
import TimerC from "../images/iroll-timer-C.gif"
import GifSwitcher from "./GifSwitcher";

const IRoll = () => {
    const pages = [
        <div>
            <div className="page-content">
                <PopupVideo src={VideoFile} />
            </div>
            <div className="page-description">
                <p>This is the current state of  the 3D platformer I’m producing as a solo developer.</p>
                <p>All animations, models, UIs, shaders and effects are original.</p>
            </div>
        </div>,
        <div>
            <div className="page-content-column">
                <GifSwitcher gifSrc={TimerA} title="Time Deduction"/>
                <GifSwitcher gifSrc={TimerB} title="Trophy Won"/>
                <GifSwitcher gifSrc={TimerC} title="Time Out"/>
            </div>
            <div className="page-description">
                <p>The timer's different states. Its background is a single image using a custom shader.</p>
            </div>
        </div>,
        <div>
            <div className="page-content">
                <PopupVideo src={Death} />
            </div>
            <div className="page-description">
                <p>A look at one of the ways to die in this game.</p>
            </div>
        </div>,
        <div>
            <div className="page-content">
                <img src={logo}/>
            </div>
            <div className="page-description-container">
                <div className="page-description">
                    <p>The logo for the game.</p>
                </div>
            </div>
        </div>,
    ];
    
    return (
        <div>
            <div className="popup-header">
                <div className="popup-title-container">
                    <h1><span><span className="title">iroll</span></span></h1>
                    <h2>In Development</h2>    
                </div>
                <div className="popup-platform">
                    <svg viewBox="0 0 576 512">
                        <path d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z"/>
                    </svg>
                    <svg viewBox="0 0 576 512">
                        <path d="M420.6 301.9a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m-265.1 0a24 24 0 1 1 24-24 24 24 0 0 1 -24 24m273.7-144.5 47.9-83a10 10 0 1 0 -17.3-10h0l-48.5 84.1a301.3 301.3 0 0 0 -246.6 0L116.2 64.5a10 10 0 1 0 -17.3 10h0l47.9 83C64.5 202.2 8.2 285.6 0 384H576c-8.2-98.5-64.5-181.8-146.9-226.6"/>
                    </svg>
                </div>
            </div>
            <div className="popup-pages">
                <PopupPages pages={pages}/>
            </div>
        </div>
    );
};

export default IRoll;